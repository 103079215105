import React from 'react'
import PropTypes from 'prop-types'
import Layout from '../Templates/Layout'
import Container from '../Templates/Container'
import Header from '../Templates/Header'
import TagsBlock from '../Organisms/TagsBlock'

const Tags = ({ pageContext }) => {
  const { tags } = pageContext
  return (
    <Layout>
      <Header title='Tags Page'>Not Be Forgottenのタグ</Header>
      <Container>
        <TagsBlock list={tags} />
      </Container>
    </Layout>
  )
}

export default Tags

Tags.propTypes = {
  pageContext: PropTypes.shape({
    tags: PropTypes.array
  })
}